import PropTypes from 'prop-types';
import { useState, useEffect, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout, Sidebar, StepBlock } from '../../components/common';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import '../../styles/forms/layoutForm.scss';
import IconNextArrow from '../../images/IconNextArrow';
import toast from 'react-hot-toast';
import SvgLoader from '../../images/SvgLoader';
import useCustomerStore from '../../store/customerStore';

export const EnterPhoneForm = () => {
  const [restaurantInfo, setRestaurantInfo] = useState({});
  const [sender, setSender] = useState('Sms');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { slug } = useParams();
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber, updateInfo] = useCustomerStore((state) => [
    state.phoneNumber,
    state.setPhoneNumber,
    state.updateInfo
  ]);
  useEffect(() => {
    // JavaScript IIFE used to instantly invoke and fetch restuarant related to the slug
    (async () => {
      const response = await axios.get(`/restaurant/${slug}`);
      if (response.status === 200 && response.data.restaurant !== null) {
        setRestaurantInfo(response.data.restaurant);
      } else {
        navigate('/not-found');
      }
    })();
  }, []);

  console.log(sender);
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (Object.keys(restaurantInfo).length === 0) {
      toast.error(t('toast.invalidUrl'));
    }
    if (phoneNumber) {
      const response =
        sender === 'Sms'
          ? await axios.post('/customer/sendSms', { phoneNumber })
          : await axios.post('/sms/sendWhatsappSms', { phoneNumber });

      console.log(response);
      if (response.data.status === 'OK') {
        const data = {
          phoneNumber,
          sender,
          restaurantName: restaurantInfo.restaurantName,
          restaurantEmail: restaurantInfo.restaurantEmail,
          slug: restaurantInfo.slug
        };
        updateInfo(data);
        setIsLoading(false);
        navigate('/verify-otp');
      }
    } else {
      toast.error(t('toast.enterPhoneNumber'));
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <Suspense>
          <form className="layout-form" onSubmit={handleSubmit}>
            <StepBlock step={1} totalStep={3} heading={t('customer.verifyPhone')} />
            <input
              className="form-input"
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
              onInput={(e) => e.target.setCustomValidity('')}
              required
            />
            <div className="radio-buttons">
              <label className="receive-otp-label">{t('customer.receiveOtp')}</label>
              <label>
                <input
                  type="radio"
                  value=""
                  name="otp"
                  defaultChecked
                  onClick={() => setSender('Sms')}
                />{' '}
                Sms
              </label>
              <label>
                <input
                  type="radio"
                  value="whatsapp"
                  name="otp"
                  onClick={() => setSender('WhatsApp')}
                />{' '}
                Whatsapp
              </label>
              <label className="otp-warning">{t('customer.otpWarning')}</label>
            </div>

            <div className="actions">
              <button type="submit" className="actions-next-link">
                {isLoading ? (
                  <SvgLoader />
                ) : (
                  <span>
                    {t('customer.next')} <IconNextArrow />
                  </span>
                )}
              </button>
            </div>
          </form>
        </Suspense>
      </Layout>
    </div>
  );
};

EnterPhoneForm.propTypes = {
  match: PropTypes.object
};
